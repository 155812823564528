import { Button } from "@/components/ui/button";
import { useEffect, useRef, useState } from "react";
import { ArrowDown } from "lucide-react";
import { useParams } from "react-router-dom";
import { InputField } from "../components/inputField";
import { Messages } from "../components/messages";
import { Suggestions } from "../components/suggestions";
import { useChat } from "@/hooks/useChat";
import { debounce } from "@/utils";
import { Toaster } from "@/components/ui/sonner-toast";
import { ContextToggle } from "@/components/contextToggle";
import { Sidebar } from "@/components/sidebar/sidebar";
import { MobileHeader } from "@/components/mobileHeader";
import { useAutoScroll } from "@/hooks/useAutoScroll";

const Chat = () => {
  const { sessionId } = useParams();
  const [scrollButtonToggled, setScrollButtonToggled] = useState(false);
  const { messages, streamMessage, isLoading, isFetching } = useChat();
  const scrollContainer = useRef<HTMLDivElement>(null);
  useAutoScroll(scrollContainer);

  // Send message to API endpoint and update history with response
  async function handleSendMessage(text: string) {
    if (!text) return;

    streamMessage({
      userMessage: text,
      sessionId: sessionId || crypto.randomUUID(),
    });
  }

  // Hacky way of hiding scroll button when navigating to different conversation
  useEffect(() => {
    setScrollButtonToggled(false);
  }, [sessionId]);

  const handleScroll = debounce(() => {
    if (!scrollContainer.current) return;
    // Show button when user scrolls 25 pixels from the bottom of the chat
    if (
      scrollContainer.current.scrollTop <
      scrollContainer.current.scrollHeight -
        scrollContainer.current.clientHeight -
        25
    ) {
      setScrollButtonToggled(true);
    } else {
      setScrollButtonToggled(false);
    }
  }, 50);

  return (
    <>
      <div className="flex flex-col md:flex-row">
        <Sidebar />
        <MobileHeader />
        <ContextToggle className="fixed z-20 h-8 py-0 md:px-5 top-14 md:top-5 right-3 rounded-2xl bg-blue hover:bg-[#9eb2c1]" />
        <div className="relative flex flex-col items-center w-full pt-12 text-sm h-dvh md:pt-0 bg-blue-light text-blue">
          <div
            id="chat"
            className="flex flex-col items-end w-full h-full max-w-full px-5 pb-5 mb-auto space-y-2 overflow-y-auto text-blue"
            onScroll={handleScroll}
            ref={scrollContainer}
          >
            {isFetching ? null : messages.length > 0 ? (
              <Messages messages={messages} isLoading={isLoading} />
            ) : (
              <Suggestions handleSuggestionClick={handleSendMessage} />
            )}
            <Button
              className={`${
                scrollButtonToggled ? "visible" : "hidden"
              } absolute bottom-40 right-1/2 -mr-2 z-30 size-7 rounded-full outline outline-1 outline-white/30 bg-blue hover:bg-blue`}
              size={"icon"}
              onClick={() => {
                setScrollButtonToggled(false);
                if (scrollContainer.current) {
                  scrollContainer.current.scrollTo({
                    top:
                      scrollContainer.current.scrollHeight -
                      scrollContainer.current.clientHeight,
                    behavior: "smooth",
                  });
                }
              }}
            >
              <ArrowDown className="text-white size-4" />
            </Button>
          </div>
          <InputField
            handleSendMessage={handleSendMessage}
            isPending={isLoading}
          />
        </div>
      </div>
      <Toaster
        className="pointer-events-auto"
        position="top-center"
        toastOptions={{ className: "bg-[#142645]" }}
      />
    </>
  );
};

export { Chat };
