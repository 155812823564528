import { memo, useEffect, useState } from "react";
import { GoogleMap } from "./googleMap";
import { DirectionsRenderer } from "./directions";
import { Button } from "@/components/ui/button";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { Navigation, SquareArrowOutUpRight } from "lucide-react";
import {
  AdvancedMarker,
  useMapsLibrary,
  ControlPosition,
  MapControl,
} from "@vis.gl/react-google-maps";
import {
  INVALID_COORDS,
  isInvalidCoords,
  useDirectionsService,
} from "@/hooks/useDirectionsService";
import { toast } from "sonner";

const geolocation = navigator.geolocation;

/** Success callback for getGeolocation */
const success = (position: GeolocationPosition) => {
  const coords: google.maps.LatLngLiteral = {
    lat: position.coords.latitude,
    lng: position.coords.longitude,
  };
  return coords;
};

const Map = memo(function MapComponent({
  originAddress,
  destinationAddress,
}: {
  originAddress?: string;
  destinationAddress: string;
}) {
  const [showMap, setShowMap] = useState<boolean>(false);
  const [origin, setOrigin] = useState<
    null | string | google.maps.LatLngLiteral
  >(null);
  const [destinationCoords, setDestinationCoords] =
    useState<null | google.maps.LatLngLiteral>(null);
  const [userLocationPermission, setUserLocationPermission] =
    useState<boolean>(false);
  const directions = useDirectionsService(origin, destinationCoords);
  const placesLib = useMapsLibrary("places");

  // Set destination coordinates when map is shown
  useEffect(() => {
    if (!showMap || !placesLib || destinationCoords) return;
    generateCoordinates(destinationAddress);

    async function generateCoordinates(location: string) {
      if (!placesLib) return;
      try {
        const request = {
          textQuery: location,
          fields: ["location"],
        };
        const { places } = await placesLib.Place.searchByText(request);

        if (places.length === 0 || !places[0].location) {
          throw new Error("Google Maps could not locate that address");
        }

        // Convert result to latlng literal
        const coords: google.maps.LatLngLiteral = {
          lat: places[0].location.lat(),
          lng: places[0].location.lng(),
        };
        setDestinationCoords(coords);
      } catch (err) {
        console.error(err);
        toast.error("Could not load map");
        setDestinationCoords(INVALID_COORDS);
      }
    }
  }, [showMap, placesLib, destinationCoords, destinationAddress]);

  if (originAddress && !origin) {
    setOrigin(originAddress);
  }

  function getGeolocation() {
    // If geolocation object failed to init, location is not supported by browser
    if (!geolocation) {
      setOrigin(INVALID_COORDS);
      return;
    }
    geolocation.getCurrentPosition(
      (position) => {
        const coords = success(position);
        setOrigin(coords);
      },
      (error) => {
        setOrigin(INVALID_COORDS);
        setUserLocationPermission(false);
        toast.error("Location access denied! Try enabling your location.");
        console.warn(error);
      },
      { enableHighAccuracy: true, maximumAge: 0 }
    );
  }

  function handleUserLocation() {
    setUserLocationPermission(true);
    getGeolocation();
  }

  // Hide map until user decides to show it
  if (!showMap) {
    return (
      <Button
        onClick={() => setShowMap(true)}
        className="self-center px-6 mt-3 mb-2 bg-gray shadow-[inset_0_-3px] shadow-red hover:bg-gray/80"
      >
        Show Map
      </Button>
    );
  }

  // Display loading spinner while destination hasn't been geocoded
  return !destinationCoords ? (
    <LoadingSpinner className="self-center mt-1 justify-self-center" />
  ) : (
    <>
      {
        // If destination failed to geolocate, do not show map
        isInvalidCoords(destinationCoords) ? null : (
          <GoogleMap mapId={"mapId"} defaultCenter={destinationCoords}>
            {directions ? (
              <DirectionsRenderer directions={directions} />
            ) : (
              <>
                <MapControl position={ControlPosition.TOP_LEFT}>
                  <Button
                    className="bg-white rounded-sm shadow-[0px_1px_5px_rgba(0,0,0,.2)] text-[rgb(90,90,90)] px-2 mt-3 ml-3 hover:bg-white hover:text-[rgb(25,25,25)] disabled:opacity-100"
                    onClick={handleUserLocation}
                    disabled={userLocationPermission}
                    aria-label={"Use my location"}
                    title={"Use my location"}
                  >
                    {userLocationPermission ? (
                      <LoadingSpinner className="p-0 size-5" />
                    ) : (
                      <Navigation className="size-5" />
                    )}
                  </Button>
                </MapControl>
                <AdvancedMarker position={destinationCoords} />
              </>
            )}
          </GoogleMap>
        )
      }
      <ExternalMapLink
        link={
          isInvalidCoords(origin)
            ? `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                destinationAddress
              )}`
            : `https://www.google.com/maps/dir/?api=1&${
                originAddress
                  ? "origin=" + encodeURIComponent(originAddress)
                  : ""
              }&destination=${encodeURIComponent(destinationAddress)}`
        }
      />
      <Button
        onClick={() => setShowMap(false)}
        className="self-center px-6 mt-3 mb-2 bg-gray shadow-[inset_0_-3px] shadow-red hover:bg-gray/80"
      >
        Hide Map
      </Button>
    </>
  );
});

const ExternalMapLink = ({ link }: { link: string }) => {
  return (
    <a
      href={link}
      target="_blank"
      className="flex flex-row items-center mt-1 ml-3 underline w-max"
    >
      <p>Open in Google Maps</p>{" "}
      <SquareArrowOutUpRight className="ml-1 size-3" />
    </a>
  );
};

export { Map };
