import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export const SquarePen = ({ className }: Props) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M30.3462 15.4243V31.8578C30.3462 32.199 30.0691 32.476 29.728 32.476H3.72846C3.38734 32.476 3.11025 32.199 3.11025 31.8578V5.85939C3.11025 5.51828 3.38734 5.24118 3.72846 5.24118H20.5199C20.6836 5.24118 20.841 5.17611 20.9575 5.0596L22.66 3.35716C23.0494 2.96776 22.7733 2.30127 22.2223 2.30127H3.6256C1.71849 2.30232 0.171387 3.84837 0.171387 5.75653V31.9607C0.171387 33.8689 1.71849 35.4149 3.6256 35.4149H29.8298C31.7379 35.4149 33.284 33.8678 33.284 31.9607V13.7219C33.284 13.1709 32.6175 12.8948 32.2281 13.2842L30.5256 14.9867C30.4091 15.1032 30.3441 15.2596 30.3441 15.4243H30.3462Z"
        fill="white"
      />
      <path
        d="M34.3892 5.7746L16.053 24.1108C15.843 24.3208 15.6028 24.4847 15.3495 24.5986C15.2864 24.6702 15.2044 24.7305 15.1093 24.7729L9.23897 27.4005C8.76147 27.6124 8.33388 27.2075 8.52696 26.7253L10.9512 20.6515C10.9936 20.546 11.0576 20.4556 11.1377 20.3868C11.2516 20.1335 11.4155 19.8933 11.6255 19.6833L29.9626 1.34713C30.8489 0.460886 32.24 0.414737 33.0716 1.24636L34.4909 2.66567C35.3216 3.49635 35.2764 4.8893 34.3901 5.7746H34.3892Z"
        fill="white"
      />
    </svg>
  );
};
