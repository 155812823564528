import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export const Ellipsis = ({ className }: Props) => {
  return (
    <svg
      width="22"
      height="6"
      viewBox="0 0 22 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="2.54309" cy="2.67474" r="2.5" fill="white" />
      <circle cx="10.9332" cy="2.67474" r="2.5" fill="white" />
      <circle cx="19.3233" cy="2.67474" r="2.5" fill="white" />
    </svg>
  );
};
