import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import { SquarePen } from "@/components/icons/squarePen";
import { useNavigate, useParams } from "react-router-dom";

/** Button to start a new conversation */
export const NewChatButton = () => {
  const isMutating = useIsMutating();
  const navigate = useNavigate();
  const { sessionId } = useParams();
  const queryClient = useQueryClient();

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          onClick={() => {
            queryClient.invalidateQueries({
              queryKey: ["messages", sessionId],
              refetchType: "none",
            });
            navigate("/");
          }}
          disabled={!!isMutating}
          size="icon"
          variant={"ghost"}
        >
          <SquarePen className="size-6" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        <p>New Chat</p>
      </TooltipContent>
    </Tooltip>
  );
};
