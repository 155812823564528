import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { useEffect, useState } from "react";

export const INVALID_COORDS = { lat: 99, lng: 99 }; // Placeholder coords used in case of error

export const isInvalidCoords = (
  coords: google.maps.LatLngLiteral | null | string
) => {
  if (typeof coords === "string") {
    false;
  }
  return JSON.stringify(coords) === JSON.stringify(INVALID_COORDS);
};

/**  Get the directions in a google maps component */
export const useDirectionsService = (
  origin: null | string | google.maps.LatLngLiteral,
  destination: null | google.maps.LatLngLiteral
) => {
  const routesLibrary = useMapsLibrary("routes");
  const [directions, setDirections] =
    useState<null | google.maps.DirectionsResult>(null);

  // Use directions service to render route on the map
  useEffect(() => {
    if (
      !routesLibrary ||
      !origin ||
      !destination ||
      isInvalidCoords(destination) ||
      isInvalidCoords(origin)
    ) {
      return;
    }
    const directionsService = new routesLibrary.DirectionsService();
    directionsService
      .route({
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.WALKING,
        provideRouteAlternatives: false,
      })
      .then((response) => {
        setDirections(response);
      })
      .catch((err) => console.error(err));
  }, [routesLibrary, origin, destination]);

  return directions;
};
