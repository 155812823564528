import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export const Pencil = ({ className }: Props) => {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.9796 2.85451L20.8997 3.7702L5.57367 19.0232C5.57367 19.0232 5.56814 19.0287 5.56656 19.0295L5.18509 19.2009L5.10374 19.292L3.89377 19.8312L4.40082 18.5658L4.47032 18.506L4.64724 18.1146C4.64724 18.1146 4.6504 18.1107 4.65356 18.1075L19.9796 2.85451ZM20.0151 0.834503C19.5238 0.834503 19.0207 1.02943 18.6337 1.41535L3.2572 16.7171C3.08107 16.8924 2.94365 17.0928 2.84808 17.3042C2.78174 17.3616 2.72803 17.4371 2.6917 17.5251L0.659555 22.5948C0.527659 22.9218 0.740114 23.2071 1.03945 23.2071C1.10816 23.2071 1.18161 23.1921 1.25664 23.1591L6.17944 20.9662C6.25921 20.9308 6.32792 20.8797 6.38084 20.8208C6.5933 20.7257 6.79469 20.5889 6.97082 20.4136L22.3466 5.11032C23.0898 4.3707 23.1277 3.20821 22.4311 2.51575L21.2416 1.33125C20.9076 0.998776 20.4661 0.834503 20.0151 0.834503Z"
        fill="#13284C"
      />
    </svg>
  );
};
