import { createContext, useState } from "react";

interface SidebarContext {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
}
export const SidebarContext = createContext<SidebarContext | undefined>(
  undefined
);

interface SidebarProviderProps {
  children: React.ReactNode;
}

export function SidebarProvider({ children }: SidebarProviderProps) {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen((value) => !value);
  };

  return (
    <SidebarContext.Provider value={{ isSidebarOpen, toggleSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
}
