import { useRef } from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Mic } from "@/components/icons/mic";
import { ArrowDownLeft } from "./icons/arrowDownLeft";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";

export function InputField({
  handleSendMessage,
  isPending,
}: {
  handleSendMessage: (text: string) => Promise<void>;
  isPending: boolean;
}) {
  const textAreaElement = useRef<HTMLTextAreaElement>(null);

  const textAreaHeightAdjust = () => {
    if (!textAreaElement.current) {
      return;
    }

    textAreaElement.current.style.height = "auto";
    textAreaElement.current.style.height = `${textAreaElement.current.scrollHeight}px`;
  };

  function handleClick() {
    if (!textAreaElement.current) return;

    const text = textAreaElement.current.value;
    if (text.length < 1) return;
    handleSendMessage(text);

    // Resize text box
    textAreaElement.current.value = "";
    textAreaElement.current.style.height = "auto";
    textAreaElement.current.style.height = `${textAreaElement.current.scrollHeight}px`;
  }

  return (
    <div
      id="inputfield"
      className="sticky bottom-0 z-10 flex justify-center w-full px-5 bg-inherit"
    >
      <div className="flex flex-col w-full px-2 py-3 max-w-MAX min-h-28 rounded-t-2xl bg-white shadow-[0_-3px] shadow-blue">
        <Textarea
          id="textInput"
          autoFocus
          placeholder="Enter prompt here..."
          className="flex-grow min-h-[50px] text-base row-auto overflow-y-none scroll-smooth border-none outline-none resize-none pb-auto max-h-[100px] sm:max-h-[200px] focus-visible:ring-offset-0 focus-visible:ring-0 bg-inherit"
          ref={textAreaElement}
          onChange={textAreaHeightAdjust}
          onKeyUp={(e) => {
            if (isPending) return;
            e.key === "Enter" && !e.shiftKey && handleClick();
          }}
        ></Textarea>
        <div className="flex flex-row items-start w-full mt-auto">
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                id="microphone"
                size="sm"
                disabled={isPending}
                className="text-blue"
              >
                <Mic className="size-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Speech to Text</p>
            </TooltipContent>
          </Tooltip>
          <Button
            id="submit"
            size="sm"
            onClick={handleClick}
            disabled={isPending}
            className={
              "ml-auto mr-3 text-2xs text-blue focus-visible:ring-offset-1 bg-gray hover:bg-gray/80 shadow-[inset_0_-4px] shadow-red"
            }
          >
            Send Message <ArrowDownLeft className="ml-1 size-3" />
          </Button>
        </div>
      </div>
    </div>
  );
}
