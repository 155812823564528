import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export const Trash = ({ className }: Props) => {
  return (
    <svg
      width="19"
      height="24"
      viewBox="0 0 19 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_241_392)">
        <path
          d="M3.15636 21.7618C3.25632 22.8806 4.19311 23.7375 5.31627 23.7375H14.3443C15.4675 23.7375 16.4043 22.8799 16.5035 21.7618L17.7966 7.24646C17.8423 6.73379 17.4389 6.29324 16.9241 6.29324H2.7358C2.22171 6.29324 1.81757 6.73451 1.86327 7.24646L3.15636 21.7618ZM12.5136 10.3189C12.5136 9.93474 12.8363 9.62557 13.2262 9.64913C13.5846 9.67056 13.8559 9.98615 13.8559 10.3453V19.6861C13.8559 20.0453 13.5853 20.3601 13.2262 20.3823C12.837 20.4058 12.5136 20.0967 12.5136 19.7125V10.3196V10.3189ZM9.15912 10.3189C9.15912 9.93474 9.48257 9.62557 9.87171 9.64913C10.2301 9.67056 10.5015 9.98615 10.5015 10.3453V19.6861C10.5015 20.0453 10.2309 20.3609 9.87171 20.3823C9.48257 20.4058 9.15912 20.0967 9.15912 19.7125V10.3196V10.3189ZM5.80466 10.3189C5.80466 9.93474 6.12739 9.62557 6.51725 9.64913C6.87568 9.67056 7.14701 9.98615 7.14701 10.3453V19.6861C7.14701 20.0453 6.87568 20.3609 6.51725 20.3823C6.12811 20.4058 5.80466 20.0967 5.80466 19.7125V10.3196V10.3189Z"
          fill="#B1102B"
        />
        <path
          d="M18.0806 0.468322L1.15368 3.33434C0.787033 3.39642 0.540132 3.74397 0.602212 4.11062L0.69173 4.63932C0.75381 5.00597 1.10136 5.25287 1.46801 5.19079L18.395 2.32477C18.7616 2.26269 19.0085 1.91514 18.9464 1.54849L18.8569 1.01979C18.7948 0.653142 18.4473 0.406242 18.0806 0.468322Z"
          fill="#B1102B"
        />
        <path
          d="M11.5219 0.19976L7.33046 0.909446C7.11543 0.945856 6.97062 1.1497 7.00703 1.36474L7.20981 2.5624C7.24622 2.77744 7.45006 2.92225 7.6651 2.88584L11.8566 2.17615C12.0716 2.13974 12.2164 1.9359 12.18 1.72086L11.9772 0.523198C11.9408 0.308158 11.737 0.16335 11.5219 0.19976Z"
          fill="#B1102B"
        />
      </g>
      <defs>
        <clipPath id="clip0_241_392">
          <rect
            width="18.3631"
            height="23.5434"
            fill="white"
            transform="translate(0.593018 0.194092)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
