import { createBrowserRouter } from "react-router-dom";
import ErrorPage from "@/pages/error-page";
import { Chat } from "@/pages/chat";
import { Providers } from "@/providers";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Providers>
        <Chat />
      </Providers>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/s/:sessionId",
    element: (
      <Providers>
        <Chat />
      </Providers>
    ),
    errorElement: <ErrorPage />,
  },
]);
