import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export const Mic = ({ className }: Props) => {
  return (
    <svg
      width="15"
      height="24"
      viewBox="0 0 15 24"
      fill="#13284C"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4285 8.90086V11.4929C14.4285 13.433 13.6347 15.1992 12.356 16.4773C11.2301 17.6039 9.72502 18.3542 8.05617 18.5166V21.6848H11.5005C11.875 21.6848 12.1815 21.9911 12.1815 22.3689C12.1815 22.7467 11.8752 23.0504 11.5005 23.0504H3.24256C2.8684 23.0504 2.55841 22.7435 2.55841 22.3689C2.55841 22.1801 2.63612 22.0087 2.76015 21.8851C2.88439 21.7614 3.05558 21.6848 3.24235 21.6848H6.68727V18.5166C5.01842 18.354 3.51359 17.6037 2.38702 16.4773C1.10894 15.1992 0.314697 13.433 0.314697 11.4929V8.90086C0.314697 8.5263 0.621609 8.21631 0.995767 8.21631C1.36992 8.21631 1.68032 8.5263 1.68032 8.90086V11.4929C1.68032 13.0558 2.32244 14.4794 3.35224 15.5092C4.38574 16.5421 5.8061 17.1811 7.37162 17.1811C8.93714 17.1811 10.3581 16.5421 11.391 15.5092C12.4208 14.4794 13.0635 13.0558 13.0635 11.4929V8.90086C13.0635 8.5263 13.3698 8.21631 13.7446 8.21631C14.1194 8.21631 14.4287 8.5263 14.4287 8.90086H14.4285Z"
        fill="currentColor"
        fillOpacity="0.75"
      />
      <path
        d="M7.37186 0.0454102C8.58577 0.0454102 9.69307 0.542579 10.4904 1.34317C11.291 2.14377 11.7882 3.24779 11.7882 4.4617V11.3155C11.7882 12.5324 11.291 13.6367 10.4904 14.4371C9.69307 15.2377 8.58577 15.7348 7.37186 15.7348C4.94096 15.7348 2.95557 13.7461 2.95557 11.3152V4.4617C2.95557 3.24779 3.45274 2.14377 4.25005 1.34317C5.05065 0.542579 6.15467 0.0454102 7.37186 0.0454102ZM7.37186 1.41431C6.53252 1.41431 5.77067 1.75649 5.21855 2.3084C4.66337 2.86379 4.32119 3.62564 4.32119 4.4617V11.3155C4.32119 12.9941 5.69317 14.3661 7.37186 14.3661C8.2112 14.3661 8.97305 14.0239 9.52516 13.4718C10.0806 12.9166 10.4227 12.1548 10.4227 11.3155V4.4617C10.4227 3.62564 10.0806 2.86379 9.52516 2.3084C8.97305 1.75628 8.2112 1.41431 7.37186 1.41431Z"
        fill="currentColor"
        fillOpacity="0.75"
      />
    </svg>
  );
};
