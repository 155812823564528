import { cn } from "@/utils";
import { CircleX } from "lucide-react";
import { forwardRef } from "react";

export interface ErrorProps extends React.HtmlHTMLAttributes<HTMLDivElement> {}

const ErrorMessage = forwardRef<HTMLDivElement, ErrorProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <div
        className={cn("flex flex-row items-center", className)}
        ref={ref}
        {...props}
      >
        <CircleX className="h-3 text-red-500 sm:h-4" />
        <p className="text-red-600">{children}</p>
      </div>
    );
  }
);

export { ErrorMessage };
