import { postRaffle } from "@/lib/raffle";
import {
  TChatLog,
  TChatMessage,
  TResponseData,
  TSystemMessage,
  TUserMessage,
} from "@/types/message";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { parse as parsePartialJSON } from "best-effort-json-parser";
import { useSession } from "./useSession";

const parseStreamedResponse = (json: string) => {
  try {
    const data = parsePartialJSON(json);
    return "message" in data ? data.message : "";
  } catch (err) {
    return "";
  }
};

/** Hook for managing chat history state */
export const useChat = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [messages, setMessages] = useState<TChatMessage[]>([]);
  const { sessionId, updateSessionList, remove } = useSession();
  const { isPending, isFetching, data, isError } = useQuery({
    queryKey: ["messages", sessionId],
    queryFn: ({ signal }) => fetchChatHistory(sessionId, signal),
    staleTime: 60000,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const chatMutation = useMutation({
    mutationKey: ["messages"],
    mutationFn: streamResponse,
    retry: false,

    onMutate: async (params) => {
      await queryClient.cancelQueries({
        queryKey: ["messages", params.sessionId],
      });

      // Save previous state of messages
      const previousMessages = queryClient.getQueryData([
        "messages",
        params.sessionId,
      ]);

      const userMessage: TChatMessage = {
        id: crypto.randomUUID(),
        owner: "user",
        message: params.userMessage,
      };

      // Optimistically update user message
      addMessage(userMessage);
      params.sessionId;

      return { previousMessages };
    },

    onSuccess: (data, params) => {
      updateLastMessage(data.message, data.directions);

      //! RAFFLE FEAT
      if (data.email && data.name) {
        postRaffle(data.name, data.email);
      }

      // Invalidate queries on success to ensure outdated messages are not fetched
      queryClient.invalidateQueries({
        queryKey: ["messages", params.sessionId],
        refetchType: "none",
      });

      // Initializing a new session
      if (!sessionId) {
        // Initialize query data at new session route to prevent it from refetching
        // This stops flickering from occuring
        queryClient.setQueryData(["messages", params.sessionId], null);
        updateSessionList(params.sessionId);
        navigate(`/s/${params.sessionId}`);
      }
    },

    onError: (_err, params, context) => {
      // Reset chatlog state to previous messages on error
      if (context?.previousMessages) {
        queryClient.setQueryData(
          ["messages", params.sessionId],
          context?.previousMessages
        );
      } else {
        clearHistory();
      }

      queryClient.invalidateQueries({
        queryKey: ["messages", params.sessionId],
      });

      toast.error("Server Error: Please try again");
    },
  });

  // Update chat messages when query data resolves
  useEffect(() => {
    if (data) {
      setMessages(data);
    }
  }, [data]);

  /** Streamed version of getting a response */
  async function streamResponse({
    userMessage,
    sessionId,
  }: {
    userMessage: string;
    sessionId: string;
  }): Promise<TResponseData> {
    try {
      //! TEMP: Switch context, will be replaced later by automatic context switching
      let context = localStorage.getItem("Context");
      if (!context) {
        context = "default";
        localStorage.setItem("Context", context);
      }
      const url = import.meta.env.VITE_PROMPT_BASE_URL + `/chats/openai`;
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          sessionId: sessionId,
          userInput: userMessage,
          responseType: "stream",
          context: context,
        }),
      });

      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      }

      const stream = response.body?.pipeThrough(new TextDecoderStream());

      // Initialize empty response message which will be updated by stream
      const responseMessage: TChatMessage = {
        id: crypto.randomUUID(),
        owner: "system",
        message: "",
      };
      addMessage(responseMessage);

      let completion = "";

      if (stream) {
        for await (const chunk of streamToAsyncIterable(stream)) {
          completion = completion + chunk;
          // Update the last message in the array with the current stream data
          updateLastMessage(parseStreamedResponse(completion));
        }
      }

      return parsePartialJSON(completion);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  /** Function to update most recent chat message. Used when streaming response */
  function updateLastMessage(
    message?: string,
    directions?: { origin: string; destination: string }
  ) {
    setMessages((prev) => {
      const updated = [...prev];
      updated[updated.length - 1] = {
        ...updated[updated.length - 1],
        message: message,
        directions: directions,
      };
      return updated;
    });
  }

  function addMessage(message: TChatMessage) {
    setMessages((prev) => [...prev, message]);
  }

  function clearHistory() {
    setMessages([]);
  }

  async function fetchChatHistory(
    sessionId: string | undefined,
    signal: AbortSignal
  ): Promise<TChatMessage[] | undefined> {
    if (!sessionId) {
      // Return empty message history if there is no sessionId
      return [];
    }

    const url = `${
      import.meta.env.VITE_PROMPT_BASE_URL
    }/chats/openai/${sessionId}`;
    try {
      const response = await fetch(url, { signal });
      if (!response.ok) {
        throw new Error(`Fetch history response status: ${response.status}`);
      }

      const json: TChatLog = await response.json();
      const messages = Array.from(json?.message);
      const history = messages.map((message) => convertMessage(message));
      return history;
    } catch (err) {
      if (err instanceof Error) {
        // Silently handle abort errors
        if (err.name === "AbortError") {
          return;
        }
        // Automatically remove sessions that are not found
        remove(sessionId);
        toast.error("Session Not Found");
        navigate("/", { replace: true });
      }
      console.error(err);
      throw err;
    }
  }

  function streamMessage(params: { userMessage: string; sessionId: string }) {
    chatMutation.mutate(params);
  }

  return {
    /** Array of chat messages */
    messages: messages,
    /** Send a message to the API, triggering a streamed response */
    streamMessage,
    /** Check if a request is currently in progress */
    isLoading: chatMutation.isPending,
    /** True when the sessions chat history is currently being fetched */
    isFetching: isPending || isFetching,
    isError,
  };
};

// Convert messages from api to TChatMessage
function convertMessage(message: TUserMessage | TSystemMessage): TChatMessage {
  if ("user" in message) {
    return {
      id: crypto.randomUUID(),
      owner: "user",
      message: message.user,
    };
  } else {
    return {
      id: crypto.randomUUID(),
      owner: "system",
      message: message.bot.message,
      directions: message.bot.directions,
    };
  }
}

async function* streamToAsyncIterable(stream: ReadableStream) {
  const reader = stream.getReader();
  try {
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      yield value;
    }
  } finally {
    reader.releaseLock();
  }
}
