import { NewChatButton } from "./newChatButton";
import { SidebarMobile } from "./sidebar/sidebarMobile";
import fsLogo from "../assets/brand/fs-logo.svg";

export const MobileHeader = () => {
  return (
    <header className="fixed top-0 z-20 flex flex-row items-center justify-start w-full h-12 px-2 py-2 text-white md:hidden bg-blue-dark">
      <SidebarMobile />
      <img src={fsLogo} className="mx-auto size-9" alt="Bulldog Genie Logo" />
      <NewChatButton />
    </header>
  );
};
