//! Deprecated: open ai tools are used instead
export async function postRaffle(name: string, email: string) {
  await fetch(`${import.meta.env.VITE_PROMPT_BASE_URL}/raffle`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: name,
      email: email,
    }),
  }).catch((err) => {
    console.error(err);
  });
}
