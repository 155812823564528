import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { memo, useEffect } from "react";

/**  Render directions in a google maps component. This is a component instead of a hook so that we can nest it inside a map component */
export const DirectionsRenderer = memo(function Directions({
  directions,
}: {
  directions: google.maps.DirectionsResult | null;
}) {
  const map = useMap();
  const routesLibrary = useMapsLibrary("routes");

  // Use directions service to render route on the map
  useEffect(() => {
    if (!routesLibrary || !map || !directions) return;
    const directionsRenderer = new routesLibrary.DirectionsRenderer({ map });
    directionsRenderer.setDirections(directions);

    return () => directionsRenderer.setMap(null);
  }, [routesLibrary, map, directions]);

  return null;
});
