import { Map, MapProps } from "@vis.gl/react-google-maps";
import { ReactNode } from "react";
import { cn } from "@/utils";

type GoogleMapProps = MapProps & {
  children?: ReactNode;
};

// Wrapper to consolidate shared attributes/styling of map instances
const GoogleMap: React.FC<GoogleMapProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <Map
      className={cn(
        "min-h-[20rem] sm:min-h-[30rem] max-w-full w-[999px] p-2 mt-3 map-border",
        className
      )}
      reuseMaps={true}
      disableDefaultUI={true}
      gestureHandling={"cooperative"}
      fullscreenControl={true}
      defaultZoom={17}
      {...props}
    >
      {children}
    </Map>
  );
};

export { GoogleMap };
