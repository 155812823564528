import { useRef, useState } from "react";
import { Button } from "@/components/ui/button";
import { Popover } from "@/components/ui/popover";
import { PopoverTrigger, PopoverContent } from "@/components/ui/popover";
import { Input } from "@/components/ui/input";
import { useNavigate } from "react-router-dom";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { toast } from "sonner";
import { useSession } from "@/hooks/useSession";
import { Trash } from "../icons/trash";
import { Pencil } from "../icons/pencil";
import { Ellipsis } from "../icons/ellipsis";

export const SidebarContent = () => {
  const { sessionList } = useSession();

  return (
    <>
      <h2 className="self-start pl-2 mt-10 mb-3 text-xl font-bold text-nowrap">
        Recent chats
      </h2>
      <ul className="h-full max-h-full overflow-y-auto list-none">
        {sessionList
          .slice()
          .reverse()
          .map((session) => (
            <SidebarItem session={session} key={session.sessionId} />
          ))}
      </ul>
    </>
  );
};

interface SidebarItemProps {
  session: {
    sessionId: string;
    sessionName: string;
  };
}

const SidebarItem = ({ session }: SidebarItemProps) => {
  const isMutating = useIsMutating();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { sessionId, editName, remove } = useSession();
  const [isEditingName, setIsEditingName] = useState(false);
  const [editedName, setEditedName] = useState<string>(session.sessionName);
  const inputRef = useRef<HTMLInputElement>(null);

  function handleNameChange() {
    if (!inputRef.current) return;
    setEditedName(inputRef.current.value);
  }

  function handleNameSubmit() {
    editName(session.sessionId, editedName);
    setIsEditingName(false);
  }

  return (
    <li className="w-full space-y-0.5">
      <div
        className="flex flex-row group items-center justify-end w-full rounded-xl data-[active=true]:bg-[#6e8090] hover:bg-[#6e8090]/50"
        data-active={session.sessionId === sessionId}
      >
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant={"ghost"}
              className="h-full p-2 md:opacity-0 data-[active=true]:opacity-100 md:group-hover:opacity-100 transition-opacity duration-100 hover:bg-transparent"
              data-active={session.sessionId === sessionId}
            >
              <Ellipsis className="size-4" />
            </Button>
          </PopoverTrigger>
          <PopoverContent
            className="flex w-full p-2 px-1 rounded-lg bg-blue-light border-white/10 text-blue roboto-regular"
            align="end"
            role="menu"
          >
            <div className="flex flex-col rounded-md">
              <Button
                variant="ghost"
                size="sm"
                className="justify-start py-1.5 text-xs rounded-lg hover:bg-[#9fb5c7] h-min"
                onClick={() => setIsEditingName(true)}
              >
                <Pencil className="mr-2 size-4" />
                Edit
              </Button>
              <AlertDialog>
                <AlertDialogTrigger asChild={true}>
                  <Button
                    variant={"ghost"}
                    size="sm"
                    className="flex flex-row items-center justify-start py-1.5 text-xs hover:bg-[#9fb5c7] text-red h-min"
                  >
                    <Trash className="mr-2 size-4" />
                    <strong>Delete</strong>
                  </Button>
                </AlertDialogTrigger>
                <AlertDialogContent
                  id="cheese"
                  className="p-0 pb-2 border-4 rounded-md bg-blue-light border-red text-blue max-w-[80%] sm:max-w-lg roboto-regular"
                >
                  <AlertDialogHeader>
                    <AlertDialogTitle className="items-center px-6 py-2 text-white bg-red">
                      <div>Are you sure? </div>
                    </AlertDialogTitle>
                    <AlertDialogDescription className="px-6 pt-6">
                      This action cannot be undone. This will permanently delete
                      this conversation.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter className="px-6">
                    <AlertDialogCancel className="border-none rounded-xl hover:bg-[#b2c8d7]">
                      Cancel
                    </AlertDialogCancel>
                    <AlertDialogAction
                      onClick={() => {
                        remove(session.sessionId);
                        toast.success("Conversation has been deleted");
                      }}
                      className="bg-transparent rounded-xl text-red hover:bg-red hover:text-white"
                    >
                      Continue
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </div>
          </PopoverContent>
        </Popover>
        {isEditingName ? (
          <Input
            type="text"
            value={editedName}
            onChange={handleNameChange}
            onBlur={handleNameSubmit}
            onKeyDown={(e) => e.key === "Enter" && handleNameSubmit()}
            className="w-full px-1 roboto-regular"
            ref={inputRef}
            autoFocus
          />
        ) : (
          <Button
            variant={"ghost"}
            key={session.sessionId}
            disabled={!!isMutating}
            onClick={() => {
              if (sessionId === session.sessionId) return;
              queryClient.invalidateQueries({
                queryKey: ["messages", sessionId],
                refetchType: "none",
              });
              navigate(`/s/${session.sessionId}`);
            }}
            className="w-full h-full min-w-0 px-1 mr-auto text-left hover:bg-transparent"
          >
            <p className="w-full overflow-hidden whitespace-nowrap text-ellipsis roboto-regular">
              {session.sessionName}
            </p>
          </Button>
        )}
      </div>
    </li>
  );
};
