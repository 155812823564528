import { Button } from "@/components/ui/button";
import { useSidebar } from "@/hooks/useSidebar";
import { Menu } from "@/components/icons/menu";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { NewChatButton } from "@/components/newChatButton";
import { SidebarContent } from "./sidebarContent";
import fsLogo from "../../assets/brand/fs-logo.svg";

export const SidebarDesktop = () => {
  const { toggleSidebar } = useSidebar();
  return (
    <div className="flex flex-col w-full h-full px-3 pt-4 overflow-hidden whitespace-nowrap">
      <div
        id="sidebar-header"
        className="flex flex-row items-center justify-evenly"
      >
        <img src={fsLogo} className="mr-2 size-9" alt="Bulldog Genie Logo" />
        <h3 className="flex items-center justify-center text-xl merriweather-bold">
          Bulldog Genie
        </h3>
        <NewChatButton />
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              onClick={toggleSidebar}
              className="justify-self-start"
              size="icon"
              variant={"ghost"}
            >
              <Menu className="size-6" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Close Sidebar</p>
          </TooltipContent>
        </Tooltip>
      </div>
      <SidebarContent />
    </div>
  );
};
