import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export const ArrowDown = ({ className }: Props) => {
  return (
    <svg
      width="27"
      height="14"
      viewBox="0 0 27 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.0507817 0.676016L3.6072 2.60358C9.5521 5.82569 16.7226 5.82569 22.6675 2.60358L26.2239 0.676017L13.1373 13.7626L0.0507817 0.676016Z"
        fill="currentColor"
      />
    </svg>
  );
};
