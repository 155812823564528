import { RefObject, useEffect } from "react";

/** Enables auto scrolling on a scroll container */
const useAutoScroll = (ref: RefObject<HTMLDivElement>) => {
  useEffect(() => {
    if (!ref.current) return;
    const container = ref.current;

    // Scroll initially
    container.scrollTop = container.scrollHeight;

    // React to new messages
    const newMessageObserver = new MutationObserver(() => {
      container.scrollTo({
        top: container.scrollHeight,
        behavior: "smooth",
      });
    });

    // Keep track of last scroll height of the container to determine if user was anchored at bottom
    let lastScrollHeight = container.scrollHeight;

    // Observe response as it is streamed
    const streamObserver = new MutationObserver(() => {
      // Only auto-scroll if user was already at bottom
      const isAtBottom =
        Math.abs(
          lastScrollHeight - container.clientHeight - container.scrollTop
        ) <= 5; // 5px threshold for rounding errors
      if (isAtBottom) {
        container.scrollTop = container.scrollHeight - container.clientHeight;
      }
      lastScrollHeight = container.scrollHeight;
    });

    newMessageObserver.observe(container, {
      childList: true, // Watch for added/removed messages
    });

    streamObserver.observe(container, {
      subtree: true, // Watch all descendants, not just direct children
      characterData: true, // Watch for text content changes
    });

    // Clean up observers when component unmounts
    return () => {
      newMessageObserver.disconnect();
      streamObserver.disconnect();
    };
  }, [ref]);
};

export { useAutoScroll };
