import { useSidebar } from "@/hooks/useSidebar";
import { SidebarToggle } from "./sidebarToggle";
import { SidebarMobile } from "./sidebarMobile";
import { NewChatButton } from "../newChatButton";
import { SidebarDesktop } from "./sidebarDesktop";
import fsLogo from "../../assets/brand/fs-logo.svg";

export function Sidebar() {
  const { isSidebarOpen } = useSidebar();
  return (
    <aside
      className={`${
        isSidebarOpen ? "w-[300px]" : "w-12"
      } top-0 left-0 z-20 hidden md:flex flex-col flex-shrink-0 sticky items-center justify-start h-screen space-y-1 duration-300 text-white bg-blue-dark`}
    >
      {isSidebarOpen ? (
        <SidebarDesktop />
      ) : (
        <>
          <img src={fsLogo} className="mt-4 size-9" alt="Bulldog Genie Logo" />
          <SidebarToggle /> {/* Toggle button for desktop sidebar */}
          <SidebarMobile />
          <NewChatButton />
        </>
      )}
    </aside>
  );
}
