import { Button } from "@/components/ui/button";
import { GenieLogo } from "./icons/logo";

export type TSuggestion = {
  id: number;
  lead: string;
  body: string;
};

const suggestions: TSuggestion[] = [
  { id: 1, lead: "Tell me about the", body: "computer science program." },
  { id: 2, lead: "Tell me about the", body: "bulldog bound program." },
  { id: 3, lead: "Where is the", body: "financial aid office?" },
  { id: 4, lead: "Where is the", body: "university located?" },
];

export function Suggestions({
  handleSuggestionClick,
}: {
  handleSuggestionClick: (text: string) => Promise<void>;
}) {
  return (
    <div className="items-center self-center w-full px-5 mt-auto max-w-MAX text-blue">
      <div className="flex flex-col items-center justify-center pt-5 pb-10 md:flex-row md:pb-20">
        <GenieLogo className="overflow-hidden md:mr-5 sm:size-20 md:size-32 size-20" />
        <h2 className="mt-2 text-2xl font-bold md:mt-0 md:text-4xl text-nowrap">
          Bulldog Genie
        </h2>
      </div>
      <p className="pb-4 text-lg font-bold">
        {" "}
        Welcome to Bulldog Genie, your friendly AI assistant ready to answer
        your Fresno State questions—anytime, anywhere!
      </p>
      <p className="font-bold">Select prompts below get started</p>
      <div className="flex flex-row w-full grid-rows-4 gap-4 py-3 overflow-y-auto no-scrollbar sm:grid sm:grid-cols-2 sm:grid-rows-2">
        {suggestions.map((suggestion) => (
          <SuggestionBox
            key={suggestion.id}
            prompt={suggestion}
            handleSuggestionClick={handleSuggestionClick}
          />
        ))}
      </div>
    </div>
  );
}

export function SuggestionBox({
  prompt,
  handleSuggestionClick,
}: {
  prompt: TSuggestion;
  handleSuggestionClick: (message: string) => void;
}) {
  const promptText = prompt.lead + " " + prompt.body;

  return (
    <Button
      onClick={() => handleSuggestionClick(promptText)}
      className="flex flex-col text-nowrap items-start justify-center w-auto h-auto px-8 py-5 rounded-2xl text-left bg-white hover:outline outline-1 outline-blue/50 shadow-[0_3px] shadow-red"
    >
      <p className="text-blue">
        <strong>{prompt.lead}</strong>
      </p>
      <p className="text-[rgb(26,52,96)]">{prompt.body}</p>
    </Button>
  );
}
