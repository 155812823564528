import { useState } from "react";
import bulldogGenie from "../assets/brand/genie-avatar.png";

export const LoadingText = ({ isLoading }: { isLoading: boolean }) => {
  const loadingMessages = [
    "Crunching the numbers...",
    "Gathering the info...",
    "Finding the best answer...",
    "Piecing it together...",
    "Gathering insights...",
    "Making sure everything's right...",
  ];
  const [loadingMessage, setLoadingMessage] = useState("Thinking...");
  if (isLoading) {
    setTimeout(() => {
      setLoadingMessage(
        loadingMessages[Math.floor(Math.random() * loadingMessages.length)]
      );
    }, 5000);
  }

  return (
    <div className="flex w-full pt-2 place-content-center">
      <div className="flex flex-row self-start w-full max-w-MAX">
        <img src={bulldogGenie} className="relative -left-2 top-1 h-7 w-7" />
        <div className="flex flex-row items-center min-w-0 rounded-2xl py-3.5 px-5 text-left text-wrap break-words bg-white">
          <svg
            className="w-5 h-5 mr-3 text-black animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          {loadingMessage}
        </div>
      </div>
    </div>
  );
};
