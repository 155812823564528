import { Button } from "@/components/ui/button";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Menu } from "@/components/icons/menu";
import { SidebarContent } from "./sidebarContent";
import { NewChatButton } from "@/components/newChatButton";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import fsLogo from "../../assets/brand/fs-logo.svg";

export const SidebarMobile = () => {
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button size="icon" variant="ghost" className="flex md:hidden ">
          <Menu className="size-6" />
          <span className="sr-only">Toggle Sidebar</span>
        </Button>
      </SheetTrigger>
      <SheetContent
        side="left"
        className="inset-y-0 flex h-full w-[300px] flex-col p-0 border-[rgb(17,30,54)] border-r-2"
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <VisuallyHidden.Root>
          <SheetTitle>Sidebar</SheetTitle>
          <SheetDescription> Side Navigation Bar </SheetDescription>
        </VisuallyHidden.Root>
        <div className={`bg-blue-dark h-full`}>
          <div className="flex flex-col h-full px-3 pt-4">
            <div
              id="mobile-sidebar-header"
              className="flex flex-row items-center justify-evenly"
            >
              <img
                src={fsLogo}
                className="mr-2 size-9"
                alt="Bulldog Genie Logo"
              />
              <h3 className="flex items-center justify-center mr-auto text-xl merriweather-bold">
                Bulldog Genie
              </h3>
              <NewChatButton />
              <SheetClose asChild>
                <Button
                  className="justify-self-start"
                  variant="ghost"
                  size="icon"
                >
                  <Menu className="size-6" />
                </Button>
              </SheetClose>
            </div>
            <SidebarContent />
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
