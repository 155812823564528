import { memo } from "react";
import Markdown from "@/components/ui/markdown";
import remarkBreaks from "remark-breaks";
import { TChatMessage } from "@/types/message";
import { ErrorMessage } from "@/components/ui/errorMessage";
import { Map } from "@/components/map/map";
import { LoadingText } from "./loadingText";
import { useIsMutating } from "@tanstack/react-query";
import { cn } from "@/utils";
import genieLogo from "../assets/brand/genie-avatar.png";

const Messages = memo(
  ({
    messages,
    isLoading,
  }: {
    messages: TChatMessage[];
    isLoading: boolean;
  }) => {
    const isMutating = useIsMutating({ mutationKey: ["messages"] });
    return (
      <>
        {messages.map((message, index) => {
          // User Message
          return message.owner === "user" ? (
            <div
              className="flex w-full pt-2 place-content-center"
              key={message.id}
            >
              <div className="flex justify-end w-full max-w-MAX">
                <div className="self-end max-w-[90%] rounded-2xl py-2.5 px-5 text-left text-wrap break-words whitespace-pre-wrap flex flex-col bg-white shadow-[0_-2px] shadow-blue">
                  <p>{message.message}</p>
                </div>
              </div>
            </div>
          ) : (
            // Bot Message
            <div
              className="flex w-full pt-2 place-content-center"
              key={message.id}
            >
              <div className="flex flex-row self-start w-full max-w-MAX">
                <img
                  src={genieLogo}
                  className="relative -left-2 top-1 h-7 w-7"
                />
                <div className="flex flex-col min-w-0 rounded-2xl py-3.5 px-5 text-left text-wrap break-words bg-white shadow-[0_-2px] shadow-red">
                  {message.error ? (
                    <ErrorMessage>An error occured</ErrorMessage>
                  ) : (
                    message.message && (
                      <Markdown
                        className={cn(
                          "max-w-full text-sm prose",
                          !!isMutating &&
                            index === messages.length - 1 &&
                            "loading-dot"
                        )}
                        children={message.message}
                        remarkPlugins={[remarkBreaks]}
                      />
                      /* prose class from tailwindcss-typography allows markdown rendering when using tailwind */
                    )
                  )}
                  {message.directions?.destination &&
                    Object.keys(message.directions.destination).length > 0 && (
                      <Map
                        destinationAddress={message.directions?.destination}
                        originAddress={message.directions?.origin}
                      />
                    )}
                </div>
              </div>
            </div>
          );
        })}
        {isLoading && messages.length % 2 !== 0 && (
          <LoadingText isLoading={isLoading} />
        )}
      </>
    );
  }
);

export { Messages };
