import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export const ArrowDownLeft = ({ className }: Props) => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.7236 1.3877V8.52766C12.7236 10.2281 11.3458 11.6008 9.65046 11.6008H2.50537"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M4.65653 14.5457L1.7063 11.5955L4.65653 8.65039"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
