import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export const Menu = ({ className }: Props) => {
  return (
    <svg
      width="38"
      height="21"
      viewBox="0 0 38 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M2.55127 2.0354H35.2458"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M2.55127 10.256H35.2458"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M2.55127 18.4764H35.2458"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  );
};
