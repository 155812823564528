import React from "react";
import ReactMarkdown, { Options as RemarkProps } from "react-markdown";

export interface MarkdownProps extends RemarkProps {}

// Wrapper to convert break tags <br> into new lines before markdown rendering
const Markdown: React.FC<MarkdownProps> = ({ children, ...props }) => (
  <ReactMarkdown {...props}>
    {children ? children.replace(/<br>/g, "  \n") : null}
  </ReactMarkdown>
);

export default Markdown;
