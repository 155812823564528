import { useEffect, useState } from "react";
import { Button, ButtonProps } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useParams, useSearchParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { ArrowDown } from "@/components/icons/arrowDown";
import { cn } from "@/utils";

export function ContextToggle({ className }: ButtonProps) {
  const queryClient = useQueryClient();
  const { sessionId } = useParams();
  const [, setSearchParams] = useSearchParams();
  const [context, setContext] = useState(
    localStorage.getItem("Context") || "default"
  );

  useEffect(() => {
    // Update the URL search parmas when context changes
    setSearchParams({ context: context });
  }, [context, setSearchParams]);

  function handleToggle(value: string) {
    localStorage.setItem("Context", value);
    setContext(value);
    queryClient.invalidateQueries({
      queryKey: ["messages", sessionId],
      refetchType: "none",
    });
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          className={cn(
            "flex flex-row items-center justify-center text-white bg-blue-dark rounded-xl",
            className
          )}
        >
          <p className="hidden md:block"> Select Context </p>
          <ArrowDown className="w-4 h-4 md:ml-2" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="text-blue w-60 bg-blue-light outline-red outline-offset-0"
        align="end"
      >
        <div className="space-y-4">
          <RadioGroup value={context} onValueChange={(e) => handleToggle(e)}>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="default" id="option1" />
              <Label className="cursor-pointer" htmlFor="option1">
                Default
              </Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="BulldogBound" id="option2" />
              <Label className="cursor-pointer" htmlFor="option2">
                Bulldog Bound 🎓🐾
              </Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="DiningServices" id="option3" />
              <Label className="cursor-pointer" htmlFor="option3">
                Dining Services 🍽️
              </Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="TechServices" id="option4" />
              <Label className="cursor-pointer" htmlFor="option4">
                Technology Services 🖥️
              </Label>
            </div>
          </RadioGroup>
        </div>
      </PopoverContent>
    </Popover>
  );
}
