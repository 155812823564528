import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "@/configs/queryClient.config";
import { RouterProvider } from "react-router-dom";
import { router } from "@/configs/router.config";

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}
