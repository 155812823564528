import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createContext } from "react";

type TSessionList = {
  sessionId: string;
  sessionName: string;
}[];

type TSessionContext = {
  sessionId: string | undefined;
  sessionList: TSessionList;
  addCurrentSession: () => void;
  updateSessionList: (id: string) => void;
  remove: (id: string) => void;
  editName: (id: string, newName: string) => void;
};

const SESSION_LIST = "sessionList";

export const SessionContext = createContext<TSessionContext | null>(null);

// Utility functions
function getSessionList(): TSessionList {
  const sessionList = localStorage.getItem(SESSION_LIST);
  return sessionList ? JSON.parse(sessionList) : [];
}

export const SessionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { sessionId } = useParams();
  const navigate = useNavigate();
  const [sessionList, setSessionList] = useState<TSessionList>(
    getSessionList()
  );

  /** Add current session to session history */
  const addCurrentSession = () => {
    if (!sessionId) return;

    // First check if it already exists
    if (!sessionList.find((session) => session.sessionId === sessionId)) {
      setSessionList((prev) => {
        const updatedList = [
          ...prev,
          { sessionId: sessionId, sessionName: "New Chat" },
        ];
        localStorage.setItem(SESSION_LIST, JSON.stringify(updatedList));
        return updatedList;
      });
    }
  };

  /** Add new session to session history */
  const updateSessionList = (id: string) => {
    if (!sessionList.find((session) => session.sessionId === id)) {
      setSessionList((prev) => {
        const updatedList = [
          ...prev,
          { sessionId: id, sessionName: "New Chat" },
        ];
        localStorage.setItem(SESSION_LIST, JSON.stringify(updatedList));
        return updatedList;
      });
    }
  };

  /** Remove a session from the session history */
  const remove = (id: string) => {
    if (!id) return;

    setSessionList((prev) => {
      const updatedList = prev.filter((session) => session.sessionId !== id);
      localStorage.setItem(SESSION_LIST, JSON.stringify(updatedList));
      return updatedList;
    });

    if (id === sessionId) {
      navigate("/");
    }
  };

  /** Edit the display name of a session in the session list */
  const editName = (id: string, newName: string) => {
    if (!id || !newName.trim()) return;

    setSessionList((prev) => {
      const updatedList = prev.map((session) =>
        session.sessionId === id
          ? { ...session, sessionName: newName.trim() }
          : session
      );
      localStorage.setItem(SESSION_LIST, JSON.stringify(updatedList));
      return updatedList;
    });
  };

  return (
    <SessionContext.Provider
      value={{
        sessionId,
        sessionList,
        addCurrentSession,
        updateSessionList,
        remove,
        editName,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
