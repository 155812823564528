import { TooltipProvider } from "@/components/ui/tooltip";
import { SessionProvider } from "@/contexts/sessionContext";
import { SidebarProvider } from "@/contexts/sidebarContext";
import { APIProvider } from "@vis.gl/react-google-maps";

export const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <SessionProvider>
      <TooltipProvider>
        <SidebarProvider>
          <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_KEY}>
            {children}
          </APIProvider>
        </SidebarProvider>
      </TooltipProvider>
    </SessionProvider>
  );
};
