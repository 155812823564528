import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useSidebar } from "@/hooks/useSidebar";
import { Menu } from "@/components/icons/menu";

export const SidebarToggle = () => {
  const { toggleSidebar } = useSidebar();
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          onClick={toggleSidebar}
          size="icon"
          className="hidden md:flex"
          variant={"ghost"}
        >
          <Menu className="size-6" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        <p>Open Sidebar</p>
      </TooltipContent>
    </Tooltip>
  );
};
